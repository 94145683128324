* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.App {
  text-align: center;
}

body {
  background-color: #2c3242;
  font-family: "Inter", sans-serif;
}

.container {
  background-color: #1d222e;
  color: #a6aebe;
  display: flex;
  min-width: 75vw;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 2rem;
  margin: 4vw 10%;
  border: 2px #019f7a solid;
  border-radius: 0 100px 0 100px;
}

.clientLogin {
  margin: 2rem;
  display: table;
  height: 3rem;
  width: 50vw;
  max-width: 30rem;
}

.clienInput {
  display: table-cell;
  border: 2px #019f7a solid;
  border-radius: 10px 0 0 10px;
  width: 100%;
  height: 100%;
}
.clienInput > input {
  font-size: large;
  outline: none;
  background-color: #2c324200;
  padding: 0 1rem;
  color: #019f7a;
  border: none;
  width: 100%;
  height: 100%;
}

.copyButton {
  background-color: #019f7a;
  font-size: 20px;
  color: #1d222e;
  border: none;
  border-radius: 0 10px 10px 0;
  width: 4rem;
  height: 100%;
}

.copyButton:hover {
  transition: 300ms;
  cursor: pointer;
  color: #ffffff;
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }
  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }
  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }
  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}

.callMedia {
  height: 3rem;
  width: 20rem;
}

.pezerleButton {
  background-color: #019f7a;
  font-size: 20px;
  color: #1d222e;
  border: none;
  border-radius: 0 30px 0 30px;
  width: 100%;
  height: 100%;

  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both infinite;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
}

.pezerleButton:hover {
  transition: 300ms;
  cursor: pointer;
  color: #ffffff;
}

.endCallButton {
  background-color: #019f7a;
  font-size: 20px;
  color: #1d222e;
  border: none;
  border-radius: 0 30px 0 30px;
  width: 100%;
  height: 100%;
}

.endCallButton:hover {
  transition: 300ms;
  cursor: pointer;
  color: #ffffff;
}

.dNone {
  display: none;
}

.declineText {
  text-align: center;
  padding: 0.5em;
  font-size: 11px;
  cursor: pointer;
}

.declineText:hover {
  color: #019f7a;
  transition: 0.2s;
  color: #cd4a4a;
}

.video1,
.video2 {
  background-color: #000000;
  margin: 1vw;
  height: 25vw;
  width: 35vw;
  border: 2px #019f7a solid;
  border-radius: 20px;
}

@media (max-width: 680px) {
  .container {
    min-width: 85vw;
    margin: 10rem 2vw 0 2vw;
    border-radius: 0 75px 0 75px;
  }

  .clientLogin {
    width: 75vw;
  }

  .clienInput > input {
    font-size: medium;
  }
}
